export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'product',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'itemCode',
    label: 'field.itemCode',
    rules: 'required|max:40',
    type: 'text',
  },
  {
    key: 'itemType',
    label: 'field.type',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.branding', value: 1 },
      { text: 'field.other', value: 2 },
    ],
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'descriptionKm',
    label: 'field.descriptionKm',
    rules: 'max:2000',
    type: 'textarea',
  },
  {
    key: 'descriptionEn',
    label: 'field.descriptionEn',
    rules: 'max:2000',
    type: 'textarea',
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
